/* eslint-disable indent */
/* eslint-disable comma-dangle */
/* eslint-disable no-trailing-spaces */

type StaticImages = {
    readonly [fileName: string]: {
        uri?: string;
    };
}

const imageStaticSourcesByFileName: StaticImages = {
    'wb7aiyR16tKn5jCK6x4aKB.png': { uri: '/images/wb7aiyR16tKn5jCK6x4aKB.png' },
'8FozrVNuKP7j9DZFZg7Q98.png': { uri: '/images/8FozrVNuKP7j9DZFZg7Q98.png' },
'6ZBrKyMr4bbJiGMopMLrWf.png': { uri: '/images/6ZBrKyMr4bbJiGMopMLrWf.png' },
'q5RzxFtmQdE1cf6qsP2s4L.png': { uri: '/images/q5RzxFtmQdE1cf6qsP2s4L.png' },
'fxpPBHFpd2zeXddrqWjzTR.png': { uri: '/images/fxpPBHFpd2zeXddrqWjzTR.png' },
'7QV1Ki6LWuo4kagz1o6ou6.png': { uri: '/images/7QV1Ki6LWuo4kagz1o6ou6.png' },
'1hMDcP7EZUK1b7mWQ9jN5w.png': { uri: '/images/1hMDcP7EZUK1b7mWQ9jN5w.png' },
'x66A9zxwqKDnjxfVwdSqVo.png': { uri: '/images/x66A9zxwqKDnjxfVwdSqVo.png' },
'jE7as5K7o1DUKaEcbmjUfQ.png': { uri: '/images/jE7as5K7o1DUKaEcbmjUfQ.png' },
'k6i2q6ebyZ8pCqXCg3Hxxx.png': { uri: '/images/k6i2q6ebyZ8pCqXCg3Hxxx.png' },
'iBqZnEfCzbaErsTj5btsGE.png': { uri: '/images/iBqZnEfCzbaErsTj5btsGE.png' },
'8z2rndnV4KpWRAeuVo71od.png': { uri: '/images/8z2rndnV4KpWRAeuVo71od.png' },
'oz4NtTHNCJN1kCryA1RknY.png': { uri: '/images/oz4NtTHNCJN1kCryA1RknY.png' },
'iTwSf2zUR7ADepyiA5hGhe.png': { uri: '/images/iTwSf2zUR7ADepyiA5hGhe.png' },
'8f58yD5qE4akhnA9kpzvm5.png': { uri: '/images/8f58yD5qE4akhnA9kpzvm5.png' },
'gGcNkvVbXyr9PtEq7HYc1B.png': { uri: '/images/gGcNkvVbXyr9PtEq7HYc1B.png' },
'tfUPC5qhvPvhJs7L2noM5C.png': { uri: '/images/tfUPC5qhvPvhJs7L2noM5C.png' },
'vAyiiRBs3MvDWHM1J4sbo9.png': { uri: '/images/vAyiiRBs3MvDWHM1J4sbo9.png' },
'ki977tMNkcpmssVYtMhZS3.png': { uri: '/images/ki977tMNkcpmssVYtMhZS3.png' },
'qViHZqJQYGMfCEBrLq4pH6.png': { uri: '/images/qViHZqJQYGMfCEBrLq4pH6.png' },
'4hw7yn1GF7LCmqWxoYZRsX.png': { uri: '/images/4hw7yn1GF7LCmqWxoYZRsX.png' },
'g8kMk8CcdymYkpi5w4W6Xb.png': { uri: '/images/g8kMk8CcdymYkpi5w4W6Xb.png' },
'nhxcFYPugU9fhz2yphw1W1.png': { uri: '/images/nhxcFYPugU9fhz2yphw1W1.png' },
'74dLBU79AJvzQySeBCB2cv.png': { uri: '/images/74dLBU79AJvzQySeBCB2cv.png' },
'xvEv3YfbTY7hruT2xdcFWV.png': { uri: '/images/xvEv3YfbTY7hruT2xdcFWV.png' },
'dZkxaFJKE8swdUDYk4gtcy.png': { uri: '/images/dZkxaFJKE8swdUDYk4gtcy.png' },
'rRKBJF2RYFXBPcQk3U2wTF.png': { uri: '/images/rRKBJF2RYFXBPcQk3U2wTF.png' },
'231n48b1JJcL3a7TTxkQRG.png': { uri: '/images/231n48b1JJcL3a7TTxkQRG.png' },
'7yFYSR9w1MqDL3abGvstC3.png': { uri: '/images/7yFYSR9w1MqDL3abGvstC3.png' },
'w9bXBGCEx24W9L7DFchqT3.png': { uri: '/images/w9bXBGCEx24W9L7DFchqT3.png' },
'mtkTthX6ipmLWTqTU97ZNq.png': { uri: '/images/mtkTthX6ipmLWTqTU97ZNq.png' },
'x8xE3LcqjDpz3Xs1C7Gzag.png': { uri: '/images/x8xE3LcqjDpz3Xs1C7Gzag.png' },
'qbrmunRwYaxT9vfoSFnq3J.png': { uri: '/images/qbrmunRwYaxT9vfoSFnq3J.png' },
'3MGiGdPxwuEA5HJUf4WEpB.png': { uri: '/images/3MGiGdPxwuEA5HJUf4WEpB.png' },
'6b196WkSY2PDUep9fXVvDq.png': { uri: '/images/6b196WkSY2PDUep9fXVvDq.png' },
'4aT9vPu3Cxc5Kucjz2NTLn.png': { uri: '/images/4aT9vPu3Cxc5Kucjz2NTLn.png' },
'gQJaFFCWNneY7NXkBV5jAV.png': { uri: '/images/gQJaFFCWNneY7NXkBV5jAV.png' },
'4i3zXri3FuzuGwFeqUtpJU.png': { uri: '/images/4i3zXri3FuzuGwFeqUtpJU.png' },
'57iK4RWpifaQRa1WdvK15Q.png': { uri: '/images/57iK4RWpifaQRa1WdvK15Q.png' },
'e2bCtWdXRikdpkSr2mWQ3v.png': { uri: '/images/e2bCtWdXRikdpkSr2mWQ3v.png' },
'otTLNXgu9Vw2Gg6kz5fHC7.png': { uri: '/images/otTLNXgu9Vw2Gg6kz5fHC7.png' },
'cE8LUSsUSs8cqqHoUig49Q.png': { uri: '/images/cE8LUSsUSs8cqqHoUig49Q.png' },
'qpCSVqY7y2sgCNZbJKsbof.png': { uri: '/images/qpCSVqY7y2sgCNZbJKsbof.png' },
'qzobCBGscBRKCkmArRx1Ez.png': { uri: '/images/qzobCBGscBRKCkmArRx1Ez.png' },
'7K867oHDBvpPPmWNtKmuKM.png': { uri: '/images/7K867oHDBvpPPmWNtKmuKM.png' },
'erdsDJat3GpV6K6t7pCAqt.png': { uri: '/images/erdsDJat3GpV6K6t7pCAqt.png' },
'eFxyHQ9BedJi7v8RTvamzU.png': { uri: '/images/eFxyHQ9BedJi7v8RTvamzU.png' },
'c8JphxKvKqmqoLGhkyhsUq.png': { uri: '/images/c8JphxKvKqmqoLGhkyhsUq.png' },
'43iznmHdtLS6m7mi2d96Yh.png': { uri: '/images/43iznmHdtLS6m7mi2d96Yh.png' },
'3WrHJ5Yj3x1MQiR6emQcDS.png': { uri: '/images/3WrHJ5Yj3x1MQiR6emQcDS.png' },
'oUfv4o4DsNVQGB7x6iCiJx.jpg': { uri: '/images/oUfv4o4DsNVQGB7x6iCiJx.jpg' },
'4JcRJqvUpDkhq1K1GvTpbS.jpg': { uri: '/images/4JcRJqvUpDkhq1K1GvTpbS.jpg' },
'4QaVBZoDn1qE9dm33ntJFM.png': { uri: '/images/4QaVBZoDn1qE9dm33ntJFM.png' },
'8yXfFgZPjdspzTobULcmsC.png': { uri: '/images/8yXfFgZPjdspzTobULcmsC.png' }
}

export default imageStaticSourcesByFileName
